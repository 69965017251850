import Proj1 from "../assets/proj1.jpeg";
import Proj2 from "../assets/proj2.jpeg";
import Proj3 from "../assets/proj3.jpeg";
import proj4 from "../assets/proj4.jpeg";
import proj5 from "../assets/proj5.jpeg";
import proj6 from "../assets/proj6.jpeg";
import proj7 from "../assets/proj7.jpeg";
import proj8 from "../assets/proj8.jpeg";
import proj9 from "../assets/proj9.jpeg";
import proj10 from "../assets/proj10.jpeg";

export const ProjectList = [
  {
    name: "AgriTech Founders Diary",
    image: Proj1,
    skills: "CorelDraw, PhotoShop"
  },
  {
    name: "AgriGear",
    image: Proj2,
    skills: "CorelDraw, PhotoShop"
  },
  {
    name: "AgriTech Founders Diary",
    image: Proj3,
    skills: "CorelDraw, PhotoShop"
  },
  {
    name: "Setting Captives Free (CAC)",
    image: proj4,
    skills: "CorelDraw, PhotoShop"
  },
  {
    name: "Setting Captives Free (CAC)",
    image: proj5,
    skills: "CorelDraw, PhotoShop"
  },
  {
    name: "Pre-New 2022",
    image: proj6,
    skills: "CorelDraw, PhotoShop"
  },
  {
    name: "E-Life",
    image: proj7,
    skills: "CorelDraw, PhotoShop"
  },
  {
    name: "E-Life",
    image: proj8,
    skills: "CorelDraw, PhotoShop"
  },
  {
    name: "E-Life",
    image: proj9,
    skills: "CorelDraw, PhotoShop"
  },
  {
    name: "The Save Home (CAC Women)",
    image: proj10,
    skills: "CorelDraw, PhotoShop"
  },

]

import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GithubIcon from "@material-ui/icons/GitHub";
import "../styles/Home.css";
import Proj7 from "../assets/Dp.jpeg"

function Home() {
  return (
    <div className="home">
      <div className="about">
        <img class="pImage" src={Proj7} alt="profile-Pic" />
        <h2> Hi, My Name is Gabriel</h2>
        <div className="prompt">
          <p>A Prolific Graphic Designer with Great Passion for Innovation and Style.</p>
          <a href="https://www.linkedin.com/in/erhun-atitiuwa-771266185"> <LinkedInIcon /> </a>
          <a href="mailto:erhunatitiuwa@gmail.com"> <EmailIcon /> </a>
        </div>
      </div>
      <div className="skills">
        <h1> Skills </h1>
        <ol className="list">
          <li className="item">
            <h2>E-Flyers | Flyers | Logos</h2>
            <span>
              Online events, church programmes, wedding programmes(IV), business events etc.
            </span>
          </li>
          <li className="item">
            <h2>Flex-Banners | Posters</h2>
            <span>
              church banners, event banners, marriage cards and burial posters etc.
            </span>
          </li>
          <li className="item">
            <h2>Software Tools</h2>
            <span>CorelDraw, PhotoShop</span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;

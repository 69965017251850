import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2014 - 2017"
          iconStyle={{ background: "#2e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            University of Benin, Benin City, Edo State, Nigeria.
          </h3>

          <h4 className="vertical-timeline-element-subtitle">
            Bachelor's Degree
          </h4>

          <p> Industrial Chemistry </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2009 - 2013"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Graphic Designer - Roni-Key Computer Graphics
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Benin City, Edo State, Nigeria.
          </h4>
          <p> - Concepts creation, with the aid of graphical tools and packages (corel draw) to satisfy old and new costumers. <br></br>
              - Maintaining costumer relationship to achieve revenue goal. <br></br>
              - Promoting the company services in various social media network to increase revenue goal</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2014 - 2015"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Graphics Engineer - Digital Signage
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Benin City, Edo State, Nigeria.
          </h4>
          <p> - Concepts creation, with the aid of graphical tools and packages (corel draw) to satisfy old and new costumers. <br></br>
              - Maintaining costumer relationship to achieve revenue goal. <br></br>
              - Promoting the company services in various social media network to increase revenue goal</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2017 - 2018"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Graphics Designer/Digital Marketer - Festinspire International
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Benin City, Edo State, Nigeria.
          </h4>
          <p> - Concepts creation, with the aid of graphical tools and packages (corel draw) to satisfy old and new costumers. <br></br>
              - Maintaining costumer relationship to achieve revenue goal. <br></br>
              - Promoting the company services in various social media network to increase revenue goal</p>
        </VerticalTimelineElement>

        
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
